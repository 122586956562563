<script>
import filter from 'lodash/filter';
import get from 'lodash/get';
import head from 'lodash/head';
import intersection from 'lodash/intersection';
import { navigationErrorHandler } from '@emobg/web-utils';
import { mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { PageTabs, PageView } from '@/components';
import { USER_AUDITS_TABS } from './const';

export default {
  name: 'UserAudits',
  components: {
    PageTabs,
    PageView,
  },
  data() {
    return {
      areTabsReady: false,
      tabs: [],
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      userPermissions: state => get(state, 'user.data.permissions', []),
    }),
  },
  mounted() {
    this.tabs = USER_AUDITS_TABS;
    this.updateTabsVisibility();
  },
  methods: {
    updateTabsVisibility() {
      this.areTabsReady = false;
      this.tabs = filter(this.tabs, item => intersection(this.userPermissions, item.permissions).length);

      const filteredTabs = filter(this.tabs, ['url', this.$route.name]);

      if (!filteredTabs.length) {
        const firstTab = get(head(this.tabs), 'url');
        this.$router.push({ name: firstTab }).catch(navigationErrorHandler);
      }

      this.areTabsReady = true;
    },
  },
};
</script>

<template>
  <div
    class="UserAuditsView"
    data-test-id="user_audits-view"
  >
    <PageTabs
      v-if="areTabsReady"
      :tabs="tabs"
      data-test-id="tabs"
      are-inner-tabs
    />
    <PageView is-inner-tab-content>
      <RouterView />
    </PageView>
  </div>
</template>
