<script>
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import keys from 'lodash/keys';
import pick from 'lodash/pick';
import { sentenceCase } from '@emobg/web-utils';
import { ContentCellComponent } from '@/components';
import { differenceBySameKey } from '@/utils';

export default {
  name: 'UserAuditContentComponent',
  components: {
    ContentCellComponent,
  },
  props: {
    before: {
      type: Object,
      default: () => ({}),
    },
    after: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    areSameValues() {
      return isEqual(this.before, this.after);
    },
    keysWithDifferentValue() {
      return keys(differenceBySameKey(this.after, this.before));
    },
  },
  methods: {
    isEmpty,
    isObject,
    sentenceCase,
    changedItem(item) {
      return pick(item, this.keysWithDifferentValue);
    },
  },
};
</script>

<template>
  <div
    class="UserAuditContentComponent"
    data-test-id="user_audit_content-component"
  >
    <div class="row">
      <div
        class="col-6"
        data-test-id="before_values"
      >
        <div class="emobg-caption-1 pb-2 emobg-font-weight-bold emobg-color-ink">
          Before
        </div>
        <div v-if="isEmpty(before)">
          {{ FALLBACK_MESSAGE.dash }}
        </div>
        <div v-else-if="areSameValues">
          No data change registered
        </div>
        <div
          v-for="(value, key) in changedItem(before)"
          :key="`${key}_before`"
        >
          <ContentCellComponent
            v-if="value"
            :label="sentenceCase(key)"
            :value="value"
            class="mb-3"
          />
        </div>
      </div>
      <div
        class="col-6"
        data-test-id="after_values"
      >
        <div class="emobg-caption-1 pb-2 emobg-font-weight-bold emobg-color-ink">
          After
        </div>
        <div v-if="isEmpty(after)">
          {{ FALLBACK_MESSAGE.dash }}
        </div>
        <div v-else-if="areSameValues">
          No data change registered
        </div>
        <div
          v-for="(value, key) in changedItem(after)"
          :key="`${key}_after`"
        >
          <ContentCellComponent
            v-if="value && !isObject(value)"
            :label="sentenceCase(key)"
            :value="value.toString()"
            class="mb-3"
          />
        </div>
      </div>
    </div>
  </div>
</template>
