import {
  DATE_FORMAT, camelCaseKeys, formatUtc, sentenceCase,
} from '@emobg/web-utils';
import UserAuditContentComponent from '../components/UserAuditContentComponent';

export function contentCells(operatorTimezone) {
  return [
    {
      property: 'auditAction',
      title: 'Action',
      transformValue: value => sentenceCase(value.replace('user_', '')),
    },
    {
      property: 'createdAtTs',
      title: 'Date',
      transformValue: value => formatUtc(value, DATE_FORMAT.defaultExtended, operatorTimezone),
    },
    {
      property: 'userName',
      title: 'User',
      width: 200,
    },
    {
      component: UserAuditContentComponent,
      folded: true,
      props: result => ({
        before: camelCaseKeys(result.valueBefore),
        after: camelCaseKeys(result.valueAfter),
      }),
    },
  ];
}
