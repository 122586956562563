var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "UserAuditList" },
    [
      _c("h1", { staticClass: "mb-2" }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
      _c(
        "ui-card",
        [
          _c("MuiTable", {
            attrs: {
              "data-set": _vm.userAuditData,
              "content-cells": _vm.auditsContentCells,
              "is-loading": _vm.isUserAuditDataLoading,
              "no-data-label": _vm.FALLBACK_MESSAGE.dash,
              "data-test-id": "list",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }